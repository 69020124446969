import { Controller } from "stimulus";
import PhotoSwipeLightbox from "photoswipe/lightbox";

// NOTE: a lot of video stuff ported from:
// https://github.com/dimsemenov/photoswipe-video-plugin/blob/main/src/video-content-setup.js
export default class extends Controller {
  static targets = ["picture", "photoswipe"];
  static values = { translations: Object };

  private pictureTargets;
  private gallery;
  private translationsValue;

  onImageClick(event): void {
    event.preventDefault();

    // NOTE: rebuild gallery on each click due to async load functionality
    // (processing images in the background
    if (this.gallery) {
      this.gallery.destroy();
      this.gallery = null;
    }

    this.gallery = new PhotoSwipeLightbox({
      gallery: this.element as HTMLElement,
      children: this.pictureTargets,
      showHideAnimationType: "none",
      pswpModule: () => import("photoswipe"),
      ...this.translationsValue,
    });

    this.gallery.on("contentLoad", this.onContentLoad.bind(this));
    this.gallery.on("change", this.onChange.bind(this));
    this.gallery.on("contentDeactivate", this.onContentDeactivate.bind(this));
    this.gallery.addFilter("domItemData", (itemData, element, linkEl) => {
      const src = linkEl.href;

      if (linkEl.dataset.video === "true") {
        itemData.video = true;
      } else {
        // image flickering workarounds
        itemData.width = 1;
        itemData.height = 1;
      }
      itemData.src = src;

      return itemData;
    });

    this.gallery.on("init", () => {
      this.initPswpEvents(this.gallery.pswp);
    });

    this.gallery.init();
  }

  onContentLoad(item) {
    if (!item.content.data.video) {
      return;
    }
    item.preventDefault();

    let video = document.createElement("video");
    video.controls = true;
    video.playsInline = true;
    video.style.position = "absolute";
    video.style.left = "0";
    video.style.top = "0";
    video.src = item.content.data.src;

    item.content.element = video;
    item.content.type = "video";
  }

  onChange() {
    const currItem = this.gallery.pswp.currSlide;

    if (currItem.data.element.dataset.video === "true") {
      // noop
    } else {
      const image = new Image();
      image.src = currItem.data.src;

      image.onload = () => {
        currItem.height = image.height;
        currItem.width = image.width;
        currItem.resize();
        this.addCaption(currItem);
      };
    }
  }

  isVideoContent(content) {
    return content && content.data && content.data.video;
  }

  initPswpEvents(pswp) {
    pswp.on("pointerDown", (e) => {
      const slide = pswp.currSlide;
      if (this.isVideoContent(slide)) {
        // prevent all drag events - is really messes up the video controls
        e.preventDefault();
      }
    });

    // do not append video on nearby slides
    pswp.on("appendHeavy", (e) => {
      if (this.isVideoContent(e.slide) && !e.slide.isActive) {
        e.preventDefault();
      }
    });

    pswp.on("close", () => {
      if (this.isVideoContent(pswp.currSlide.content)) {
        // Switch from zoom to fade closing transition,
        // as zoom transition is choppy for videos
        if (
          !pswp.options.showHideAnimationType ||
          pswp.options.showHideAnimationType === "zoom"
        ) {
          pswp.options.showHideAnimationType = "fade";
        }

        // pause video when closing
        this.pauseVideo(pswp.currSlide.content);
      }
    });
  }

  pauseVideo(content) {
    if (content.element) {
      content.element.pause();
    }
  }

  onContentDeactivate({ content }) {
    if (this.isVideoContent(content)) {
      this.pauseVideo(content);
    }
  }

  addCaption(slide) {
    const caption = slide.data.element.dataset.caption;

    if (!caption) {
      return;
    }

    const captionElement = this.createCaption(slide, caption);
    slide.container.appendChild(captionElement);
  }

  createCaption(slide, caption) : HTMLParagraphElement {
    const p = document.createElement("p");
    p.className = "pswp__caption";
    p.innerText = caption;

    const imageWidth = Math.ceil(slide.width * slide.zoomLevels.initial);
    const imageHeight = Math.ceil(slide.height * slide.zoomLevels.initial);

    p.style.width = imageWidth + "px";
    const captionHeight = this.captionHeight(p);

    // The image is centered in the middle of the screen so the upper half is not available
    const availableHeight = this.gallery.pswp.viewportSize.y - (this.gallery.pswp.viewportSize.y - imageHeight) / 2;

    // To avoid render the caption outside the screen on big images or small screens
    p.style.top = Math.min(imageHeight + 8 , availableHeight - captionHeight - 8 ) + "px";

    return p;
  }

  captionHeight(p): number {
    const detachedDiv = document.createElement("div");
    detachedDiv.style.position = "absolute";
    detachedDiv.style.visibility = "hidden";
    detachedDiv.appendChild(p);
    document.body.appendChild(detachedDiv);
    const captionHeight = p.offsetHeight;
    document.body.removeChild(detachedDiv);

    return captionHeight;
  }
}
