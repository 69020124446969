import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import { Modal } from "bootstrap";
import { UppySettings } from "../components/wizard/uppy";

export default class extends Controller {
  private uppyLoaded: boolean;
  private uppy: Uppy;

  dashboardTarget: HTMLElement;
  documentTypeValue: string;
  documentTokenValue: string;
  hasToggleableButtonTarget: boolean;
  loadDashboardValue: boolean;
  modalTarget: any;
  parentIdValue: number;
  propsValue: UppySettings;
  toggleableButtonTarget: HTMLButtonElement;
  tokenValue: string;
  typeValue: string;

  static targets = ["modal", "dashboard", "toggleableButton"];

  static values = {
    documentToken: String,
    documentType: String,
    props: Object,
    parentId: Number,
    token: String,
    type: String,
    loadDashboard: Boolean,
  };

  connect(): void {
    this.uppyLoaded = false;
    if (this.loadDashboardValue) {
      this.loadUppy();
    }
  }

  async loadUppy(): Promise<void> {
    if (this.uppyLoaded) {
      return;
    }

    await import("@uppy/dashboard").then(async (dashboard) => {
      await import("../components/wizard/uppy").then(({ initUppy }) => {
        this.uppy = initUppy(this.propsValue)
          .use(dashboard.default, {
            inline: true,
            target: this.dashboardTarget,
            showProgressDetails: true,
            plugins: [],
            proudlyDisplayPoweredByUppy: false,
            showSelectedFiles: true,
            hideUploadButton: true,
            width: "100%",
            height: "100%",
          })
          .on("file-added", () => {
            if (this.hasToggleableButtonTarget) {
              this.toggleableButtonTarget.disabled = true;
            }
            this.uppy.upload().then((result) => {
              if (
                this.hasToggleableButtonTarget &&
                result.successful.length > 0
              ) {
                this.toggleableButtonTarget.disabled = false;
              }
            });
          });

        this.uppy.setMeta({ upload_type: this.typeValue });
        this.uppy.setMeta({ document_type: this.documentTypeValue });
        this.uppy.setMeta({ document_token: this.documentTokenValue });
        this.uppy.setMeta({ upload_parent_id: this.parentIdValue });
        this.uppy.setMeta({ token: this.tokenValue });
        this.uppyLoaded = true;
      });
    });
  }

  async openModal(event): Promise<void> {
    event.preventDefault();
    await this.loadUppy();
    this.uppy.cancelAll();

    const modal = new Modal(this.modalTarget);
    modal.show();
  }
}
